<template>
  <a class="p-0 md:p-4 rounded-xl md:bg-zinc-900 md:hover:bg-zinc-800 will-change-[background] transition-colors cursor-pointer min-w-[8rem] snap-center flex flex-col"
    @click="acitve = true; $router.push(`/deck/${deck.code || ''}`)">
    <!-- cover -->
    <div class="mx-auto m-0 w-full relative rounded-xl shadow-xl grid place-content-center overflow-hidden" :class="{
      'deck-cover': acitve,
    }">

      <img :src="deck.cover ? useImageProxy(deck.cover) : '/images/cover.png'"
        @error="e => { e.target.src = '/images/cover.png' }"
        class="shadow-lg h-full w-full aspect-square object-top object-cover select-none rounded-xl overflow-hidden" />

      <div v-if="deck.isPublic && user.publicId" class="absolute top-0 right-0 m-1 p-1 rounded-full bg-green-500">
        <SignalIcon class="size-6 text-white stroke-2" />
      </div>

      <button v-if="deck.isOwner" class="absolute bottom-0 right-0 m-1 p-1 rounded-full bg-zinc-800 hover:bg-red-600"
        @click.stop="doDeleteDeck">
        <TrashIcon class="size-6 text-white stroke-2" />
      </button>
    </div>

    <!-- title -->
    <div class="mt-2 md:mt-4 min-h-0 md:min-h-[62px]">
      <div class="flex gap-1 font-mono text-zinc-400">
        <BookmarkIcon class="size-4 flex-none" />
        <p class="text-xs truncate">{{ deck.code || '-' }}</p>
      </div>
      <p class="pb-1 text-sm md:text-base font-normal md:font-bold !text-white truncate" :class="{
        'deck-name': acitve,
      }">
        {{ deck.title || '-' }}
      </p>

      <p class="text-xs md:text-sm font-mono text-zinc-400 truncate">
        {{ deck.updatedAt ? formatTime(deck.updatedAt, 'YYYY-MM-DD') : '-' }}
      </p>
    </div>
  </a>
</template>

<script setup>
import { BookmarkIcon, SignalIcon } from '@heroicons/vue/24/solid'
import { TrashIcon } from '@heroicons/vue/24/outline';
const { user } = storeToRefs(useGlobalStore())
const { deleteDeck } = useDeckStore()

const { formatTime } = useTool()
const { messageConfirm } = useSwal()

const { t } = useNuxtApp().$i18n

const { deck } = defineProps({
  deck: {
    type: Object,
    default: {},
  },
})

const acitve = ref(false)

const doDeleteDeck = async () => {
  const { value } = await messageConfirm(t('headerDeck.deleteDeck'), `${t('headerDeck.deleteDeckInfo')} "${deck?.title}"`)
  if (value) {
    await deleteDeck(deck?.code)
  }
}
</script>

<style scoped>
.deck-cover {
  view-transition-name: deck-cover;
}

.deck-name {
  view-transition-name: deck-name;
}
</style>
